// partials
@import 'bootstrap.scss';
@import 'partials/layout.vars';
@import 'partials/responsive.partial';
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '@angular/material/theming';
@import url('https://fonts.googleapis.com/css?family=Comfortaa|Dancing+Script|Libre+Franklin|Lora|Montserrat|Open+Sans+Condensed:300|Oxygen|PT+Sans+Narrow|Playfair+Display|Poppins|Quicksand|Raleway|Titillium+Web|Dosis');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('../plugins/fontawesome-pro-5.3.1-web/css/all.min.css');

@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates');

@include mat-core();
$app-primary: mat-palette($mat-blue);

$app-accent: mat-palette($mat-orange, 400, 200, 600);
// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red, 500, 200, 800);
// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

html,
body {
    height: 100%;
    margin: 0;
    background: url('../img/ruido.png'), linear-gradient(rgb(250, 250, 250), rgb(250, 250, 250));
    top: 0;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

table {
    width: 100%;
}

input {
    text-align: left !important;
}

.errors span {
    color: red;
    font-size: 12px;
}

.center-text {
    text-align: center;
}

.right-text {
    text-align: right;
}

.hide {
    display: none !important;
}

.text-right {
    text-align: right !important;
}

.text-dark {
    color: $text-dark-color !important;
}

.text-muted {
    color: $text-muted-color !important;
}

.mat-table tr {
    width: 100%;
}

.page-header {
    font-size: 25px;
    text-align: left;
    margin: 15px 0px 15px 0px;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.btn-step {
    background-color: rgb(44, 43, 43);
    font-family: 'Poppins', sans-serif;
    color: white;
    margin-top: 1em;
    text-align: center;
    margin-left: 10px;
}

.btn-step:hover {
    background: gray;
    color: black;
}

.btn-step-next {
    background-color: #0066B2;
    font-family: 'Poppins', sans-serif;
    color: white;
    margin-top: 1em;
    text-align: center;
    margin-left: 10px;
}

.btn-step-next:hover {
    background: gray;
    color: #0066B2;
}

.btn-confirm {
    margin-top: 1em;
}

button.btn-confirm,
.btn-confirm button {
    font-family: 'Poppins', sans-serif;
    background-color: rgb(5, 154, 253);
    color: white;
    text-align: center;
    margin-left: 10px;
}

button.btn-edit,
button.btn-create {
    font-family: 'Poppins', sans-serif;
    background-color: $color-primary;
    color: white;
    text-align: center;
    margin-left: 10px;
}

button.btn-confirm:hover,
.btn-confirm button:hover {
    background-color: rgb(46, 56, 192);
}

button.btn-cancel,
.btn-cancel button {
    font-family: 'Poppins', sans-serif;
    background-color: red;
    color: white;
    text-align: center;
    margin-left: 10px;
}

button.btn-cancel:hover,
.btn-cancel button:hover {
    font-family: 'Poppins', sans-serif;
    background-color: rgb(204, 0, 0);
    color: white;
    text-align: center;
    margin-left: 10px;
}

div.actions-buttons {
    margin: 10px;
}

.card {
    background-color: white;
    padding: 2em 2em 2em 2em;
    box-shadow: 1px 1px 1px #a5a3a3;
    font-size: 15px;
    position: relative;
    margin-bottom: 1em;
}

.card div mat-form-field {
    width: 100%;
    font-size: 12px;
}

.btn-warning button {
    background-color: red;
    font-family: 'Poppins', sans-serif;
    color: white;
}

.btn-warning button:hover {
    background-color: rgb(95, 8, 8);
}

.btn-search {
    background-color: #1B81B5;
    color: white;
    text-align: center;
    margin-left: 10px;
}

.btn-actions {
    background-color: $bg-color-purple;
    color: white;
}

.form-inline {
    width: 100%;
    display: flex;
}

.swal-title,
.swal-text {
    font-family: 'Poppins', sans-serif;
}

.no-data-message {
    font-size: 25px;
    text-align: center;
    width: 100%;
    font-family: 'Poppins', 'sans-serif';
    margin: auto;
    padding: 3% 0%;
    color: orange;
}

.edit {
    color: orange;
    cursor: pointer;
}

.edit:hover {
    color: rgb(134, 90, 7);
}

.gray {
    color: gray;
}

.orange {
    color: orange;
}

.green {
    color: green;
}

.red {
    color: red;
}

.blue {
    color: rgb(77, 148, 240);
}

.primary-color {
    color: $color-primary;
}

.purple {
    color: $bg-color-purple;
}

.dark-yellow {
    color: $color-dark-yellow;
}

.dark-red {
    color: #B20700;
}

.dark-blue {
    color: #0066B2;
}

.mat-row:nth-child(even) {
    background-color: $table-primary-color !important;
}

.mat-row:nth-child(odd) {
    background-color: $table-secondary-color !important;
}

.mat-header-cell {
    color: rgb(68, 67, 67);
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    font-size: 12px;
    white-space: nowrap;
}

.mat-cell {
    font-family: 'Titillium Web', sans-serif;
    white-space: nowrap;
    font-size: 12px;
    padding-right: 15px !important;
}

.bandeira img {
    max-width: 25px;
    margin-left: 10px;
}

.mat-button,
.mat-raised-button {
    font-family: 'Poppins', 'sans-serif';
}

.badge {
    padding: 3px 5px;
    font-size: 12px;
    margin-top: 1px;
    display: inline-block;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    color: #ffffff;
}

.badge-dds {
    border-radius: .25rem;
    padding: 1% 1%;
    color: white;
    font-size: 10px;
    white-space: nowrap;
    margin: 0px 2px;
}

.alert-blue {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff
}

.bg-white {
    background-color: #fff;
}

.bg-green {
    background-color: $bg-color-green;
}

.bg-gray {
    background-color: $bg-color-gray;
}

.bg-red {
    background-color: $bg-color-red;
}

.bg-purple {
    background-color: $bg-color-purple;
}

.bg-yellow {
    background-color: $bg-color-yellow;
}

.bg-dark-red {
    background-color: $bg-dark-red;
}

.bg-blue {
    background-color: $bg-color-blue;
}

.bg-red-chargeback {
    background-color: #ce5e69;
}

.bg-red-refund {
    background-color: #d09298;
}


.bg-gray-green {
    border: 1px solid green;
    color: green;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}


.hover-row {
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    table.mat-table {
        display: block;
        overflow-x: scroll;
    }

    .page-header {
        font-size: 20px;
    }

}

@media screen and (min-width: 500px) {
    div mat-form-field {
        width: 95%;
    }
}

.padding-basic {
    padding: 1% 2%;
}

.mat-dialog-container {
    overflow: hidden;
    padding: 3% 3%;
}

.clickable {
    cursor: pointer;
    color: #439EFB;
}

.clickable:hover {
    color: #207CDA;
    text-decoration: underline;
}


.close-sidenav.user-message {
    margin-left: 240px !important;
}

.file-btn {
    margin-left: 10px !important;
    background-color: $bg-color-green !important;
    color: white !important;
}

app-sidenav {
    display: flex;
}

mat-drawer-container {
    flex: 1 1 auto;
}

.inner-container {
    margin-bottom: 35px;
}

button.mat-menu-item i {
    padding-right: 12px;
}

button.mat-menu-item {
    padding: 0px 30px 0px 20px;
}

.fixActionRow {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.strech table {
    width: 100% !important;
}

.report-data-card {
    margin-bottom: 15px;
}

input.invalid-field {
    color: #f44336 !important;
}

.camel-case {
    text-transform: capitalize;
}

.shadow-padrao {
    box-shadow: 1px 1px 1px #a5a3a3;
}

mat-tooltip-component>.mat-tooltip {
    white-space: pre;
}

.div-table {
    width: 100%;
    overflow: auto;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #1B81B5;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #28A5C3;
}

.btn-primary {
    color: #fff;
    background-color: #1B81B5;
    border-color: #1B81B5;
}

.btn-primary:hover {
    color: #fff;
    background-color: #28A5C3;
    border-color: #28A5C3;
}