$text-dark-color: #797979;
$text-muted-color: #80898e;
$text-black-color: #4c5667;
$table-primary-color: #fff;
$table-secondary-color: rgb(250, 250, 250);
$color-primary: #36a9e1;
$color-gray: #6b737c;
$color-dark-blue: #25476a;
$color-light-gray: #e6e6e6;
$color-warning: #e78d1a;
$color-red: #dc3545;
$color-dark-yellow: #A38930;
$bg-color-green: green;
$bg-light-gray: #eee;
$bg-color-gray: #6c757d;
$bg-color-red: #dc3545;
$bg-color-purple: rgba(114, 102, 186, 0.75);
$bg-color-yellow: #f1b53d;
$bg-color-blue: $color-primary;
$bg-color-dark-blue: #0569e4;
$bg-dark-red: #8d2424;

$bg-color-primary: $bg-color-blue;
$mat-orange: (
    50 : #fdf6e8,
    100 : #fbe9c5,
    200 : #f8da9e,
    300 : #f5cb77,
    400 : #f3c05a,
    500 : #f1b53d,
    600 : #efae37,
    700 : #eda52f,
    800 : #eb9d27,
    900 : #e78d1a,
    A100 : #ffffff,
    A200 : #fff4e6,
    A400 : #ffdcb3,
    A700 : #ffd09a,
    contrast: (
        50 : #fff,
        100 : #fff,
        200 : #fff,
        300 : #fff,
        400 : #fff,
        500 : #fff,
        600 : #fff,
        700 : #fff,
        800 : #fff,
        900 : #fff,
        A100 : #fff,
        A200 : #fff,
        A400 : #fff,
        A700 : #fff,
    )
);
$mat-blue: (
    50 : #e7f5fb,
    100 : #c3e5f6,
    200 : #9bd4f0,
    300 : #72c3ea,
    400 : #54b6e6,
    500 : #36a9e1,
    600 : #30a2dd,
    700 : #2998d9,
    800 : #228fd5,
    900 : #167ecd,
    A100 : #feffff,
    A200 : #cbe7ff,
    A400 : #98d0ff,
    A700 : #7fc4ff,
    contrast: (
        50 : #fff,
        100 : #fff,
        200 : #fff,
        300 : #fff,
        400 : #fff,
        500 : #fff,
        600 : #fff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #fff,
        A200 : #fff,
        A400 : #fff,
        A700 : #fff,
    )
);

$mat-red: (
    50 : #f7eceb,
    100 : #eccfcc,
    200 : #dfb0aa,
    300 : #d29088,
    400 : #c9786f,
    500 : #bf6055,
    600 : #b9584e,
    700 : #b14e44,
    800 : #a9443b,
    900 : #9b332a,
    A100 : #ffe0de,
    A200 : #ffb1ab,
    A400 : #ff8178,
    A700 : #ff6a5e,
    contrast: (
        50 : #fff,
        100 : #fff,
        200 : #fff,
        300 : #fff,
        400 : #fff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #fff,
        A200 : #fff,
        A400 : #fff,
        A700 : #fff,
    )
);